import { InventoryType } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { InventoryTypeView } from "../../inventory/types/views";
import { getAttributeFromView } from "../selectors";

export interface ManageInventoryTypeNameHooks {
  isEditing: boolean;
  name: string;
  invalid: boolean;

  onSave(): void;

  onEdit(): void;

  onCancelEdit(): void;

  onNameChange(name: string): void;
}

export interface ManageInventoryTypeNameProps {
  type: InventoryTypeView;

  onSave(inventoryType: InventoryType): void;

  isNameValid(name: string, id: number | undefined): boolean;
}

export function useManageInventoryTypeName(props: ManageInventoryTypeNameProps): ManageInventoryTypeNameHooks {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(props.type.name);
  const [invalid, setInvalid] = useState(false);

  const onSave = (): void => {
    setInvalid(false);
    if (props.isNameValid(name.trim(), props.type.id)) {
      props.onSave({
        id: props.type.id,
        name: name.trim(),
        customAttributes: props.type.attributes.map((a) => getAttributeFromView(a)),
        isDefault: false,
        isLocked: false,
        color: null,
        standardFieldConfiguration: props.type.standardFieldConfiguration,
      });
      setIsEditing(false);
    } else {
      setInvalid(true);
    }
  };

  const onEdit = (): void => {
    setIsEditing(true);
  };

  const onCancelEdit = (): void => {
    setIsEditing(false);
  };

  const onNameChange = (changedName: string): void => {
    setName(changedName.trim());
    setInvalid(!props.isNameValid(changedName.trim(), props.type.id));
  };

  return { isEditing, name, invalid, onSave, onEdit, onCancelEdit, onNameChange };
}
