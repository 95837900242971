import {
  InventoryStandardField,
  InventoryType,
  SiteAccount,
  SiteLocations,
  StorageLocation,
  Vendor,
} from "@labarchives/inventory-shared/build/inventory";
import { InventoryTypesState } from "../../inventorytypes/types/state";
import { StorageState } from "../../storage/types/state";
import { createReduced } from "../../utils";
import { VendorState } from "../../vendors/types/state";
import { SiteAccountView } from "./views";

export function getSiteAccountView(account: SiteAccount, locations: SiteLocations): SiteAccountView {
  return {
    id: account.id,
    name: account.name,
    building: locations.buildings.find((b) => b.id === account.buildingId)?.name || "",
    floor: locations.floors.find((f) => f.id === account.floorId)?.name || "",
    room: locations.rooms.find((r) => r.id === account.roomId)?.name || "",
    buildingId: account.buildingId,
    floorId: account.floorId,
    roomId: account.roomId,
  };
}

export function getAdminInventoryTypesState(type: InventoryType): InventoryTypesState {
  const emptyType: InventoryType = {
    id: 0,
    name: "",
    customAttributes: [],
    isDefault: false,
    isLocked: false,
    color: null,
    standardFieldConfiguration: [],
  };
  return {
    byId: { [type.id]: type },
    allIds: [type.id],
    isLoading: false,
    onTypeAdded: (): Promise<InventoryType> => Promise.resolve<InventoryType>(emptyType),
    onTypeUpdated: (): Promise<InventoryType> => Promise.resolve<InventoryType>(emptyType),
    onTypeDeleted: (): Promise<boolean> => Promise.resolve(true),
    onAttributeAdded: (): Promise<InventoryType> => Promise.resolve<InventoryType>(emptyType),
    refresh: () => Promise.resolve(),
  };
}

export function getAdminStorageState(storageLocations: StorageLocation[]): StorageState {
  const emptyLocation: StorageLocation = {
    id: 0,
    name: "",
    freezerBoxDisplayFormat: null,
    numberOfColumns: null,
    numberOfRows: null,
    parentId: null,
  };
  return {
    byId: createReduced(storageLocations),
    allIds: storageLocations.map((s) => s.id),
    isLoading: false,
    onLocationAdded: (): Promise<StorageLocation> => Promise.resolve(emptyLocation),
    onLocationUpdated: (): Promise<StorageLocation> => Promise.resolve(emptyLocation),
    onLocationDeleted: (): Promise<void> => Promise.resolve(),
    refresh: (): Promise<void> => Promise.resolve(),
  };
}

export function getAdminVendorsState(vendor: Vendor | undefined): VendorState {
  const emptyVendor: Vendor = { name: "", id: 0, contactInfo: "", notes: "", url: "" };
  return {
    allIds: vendor ? [vendor.id] : [],
    byId: vendor ? { [vendor.id]: vendor } : createReduced([]),
    isLoading: false,
    onVendorAdded: (): Promise<Vendor> => Promise.resolve(emptyVendor),
    onVendorDeleted: (): Promise<void> => Promise.resolve(),
    onVendorUpdated: (): Promise<Vendor> => Promise.resolve(emptyVendor),
    refresh: (): Promise<void> => Promise.resolve(),
  };
}
