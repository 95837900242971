import React, { ReactElement, useState } from "react";
import { FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SubscriptionType } from "@labarchives/inventory-shared/build/inventory";
import { Button, Modal } from "@labarchives/ui-design/dist";
import { CancelButton } from "../components/CancelButton/CancelButton";
import { InventoryDatePicker } from "../components/DatePicker/InventoryDatePicker";
import { SuperUserAccountDetailsView } from "./views";

export interface SuperUpdateSubscriptionModalProps {
  isOpen: boolean;
  account: SuperUserAccountDetailsView;

  onToggle(): void;
  onUpdate(begin: Date, end: Date, type: SubscriptionType, seatCount: number): void;
}

export function SuperUpdateSubscriptionModal(props: SuperUpdateSubscriptionModalProps): ReactElement {
  const [begin, setBegin] = useState(props.account.subscriptionBegin);
  const [end, setEnd] = useState(props.account.subscriptionEnd);
  const [type, setType] = useState(props.account.subscriptionType);
  const [seats, setSeats] = useState(props.account.subscriptionSeatCount);

  function onBeginChange(date: Date): void {
    setBegin(date);
  }

  function onEndChange(date: Date): void {
    setEnd(date);
  }

  function onTypeChange(value: string): void {
    setType(Number.parseInt(value));
  }

  function onSeatChange(value: string): void {
    setSeats(Number.parseInt(value));
  }

  function changeSubscription(): void {
    props.onUpdate(begin, end, type, seats);
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggle}>
      <ModalHeader toggle={props.onToggle}>Update Subscription Details</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="subscriptionBegin">Subscription Begin</Label>
          <InventoryDatePicker id="subscriptionBegin" name="subscriptionBegin" required onChange={onBeginChange} selected={begin} />
        </FormGroup>
        <FormGroup>
          <Label for="subscriptionEnd">Subscription End</Label>
          <InventoryDatePicker id="subscriptionEnd" name="subscriptionEnd" required onChange={onEndChange} selected={end} />
        </FormGroup>
        <FormGroup>
          <Label for="subscriptionType">Subscription Type</Label>
          <Input type="select" id="subscriptionType" defaultValue={type.toString()} onChange={(e) => onTypeChange(e.target.value)}>
            <option value={SubscriptionType.Free.toString()}>Free Trial</option>
            <option value={SubscriptionType.Paid.toString()}>Paid Subscription</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="subscriptionSeatCount">Paid Seat Count</Label>
          <Input type="number" id="subscriptionSeatCount" defaultValue={seats.toString()} min={0} onChange={(e) => onSeatChange(e.target.value)} />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="me-1" onClick={changeSubscription}>
          Save
        </Button>
        <CancelButton onClick={props.onToggle} />
      </ModalFooter>
    </Modal>
  );
}
