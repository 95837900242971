import React, { ChangeEvent, ReactElement, RefObject } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { CheckboxLabel } from "@labarchives/ui-design/dist/components/CheckboxLabel";
import { CheckboxInput } from "@labarchives/ui-design/dist/components/CheckboxInput";
import { CustomAttributeDefinitionView } from "../types/views";
import { RequiredLabelIndicator } from "../../components/RequiredLabelIndicator/RequiredLabelIndicator";
import { InventoryDatePicker } from "../../components/DatePicker/InventoryDatePicker";

interface CustomAttributeInputProps {
  id: number;
  inputId: string;
  type: CustomAttributeType;
  values: string[];
  definition: CustomAttributeDefinitionView;
  isValid: boolean;
  fieldName: string;
  onChange: (fieldName: string, e: ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (fieldName: string, e: ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (fieldName: string, date: Date | null) => void;
  innerRef?: RefObject<HTMLInputElement>;
}

export const CustomAttributeInput = (props: CustomAttributeInputProps): ReactElement => {
  const { type, values, definition, inputId, isValid, fieldName, onChange, onCheckboxChange, onDateChange, innerRef } = props;

  const firstValue = values.length > 0 ? values[0] : "";
  const isChecked = (value: string): boolean => values.includes(value);
  const feedback = isValid ? (
    ""
  ) : (
    <div className="invalid-feedback" style={{ display: "inline" }}>
      <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
    </div>
  );

  switch (type) {
    case CustomAttributeType.Textbox: {
      return (
        <FormGroup id={`inventory-item-display-custom${definition.id}`} className="custom-field">
          <Label for={inputId}>{definition.label}</Label>
          <RequiredLabelIndicator required={definition.required} />
          <Input
            name={fieldName}
            type="text"
            id={inputId}
            value={firstValue}
            required={definition.required}
            invalid={!isValid}
            onChange={(e) => onChange(fieldName, e)}
            innerRef={innerRef}
            maxLength={255}
            autoComplete="off"
          />
          <FormFeedback>
            <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
          </FormFeedback>
        </FormGroup>
      );
    }
    case CustomAttributeType.TextArea: {
      return (
        <FormGroup>
          <Label for={inputId}>{definition.label}</Label>
          <RequiredLabelIndicator required={definition.required} />
          <Input
            name={fieldName}
            type="textarea"
            id={inputId}
            value={firstValue}
            required={definition.required}
            invalid={!isValid}
            onChange={(e) => onChange(fieldName, e)}
            innerRef={innerRef}
          />
          <FormFeedback>
            <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
          </FormFeedback>
        </FormGroup>
      );
    }
    case CustomAttributeType.Checkbox: {
      return (
        <FormGroup>
          <label id={`${inputId}label`} htmlFor={fieldName}>
            {definition.label}
          </label>
          <RequiredLabelIndicator required={definition.required} />
          <div>
            {definition.possibleValues.map((v, index) => {
              return (
                <CheckboxLabel className="me-2" key={inputId + v}>
                  <CheckboxInput
                    name={fieldName}
                    id={inputId + index}
                    uncheckedValue="0"
                    checked={isChecked(v)}
                    value={v}
                    invalid={!isValid}
                    innerRef={innerRef}
                    onChange={(e) => onCheckboxChange(fieldName, e)}
                  />
                  <span>{v}</span>
                </CheckboxLabel>
              );
            })}
          </div>
          {feedback}
        </FormGroup>
      );
    }
    case CustomAttributeType.Number: {
      return (
        <FormGroup>
          <Label for={inputId}>{definition.label}</Label>
          <RequiredLabelIndicator required={definition.required} />
          <Input
            name={fieldName}
            type="number"
            id={inputId}
            value={firstValue}
            required={definition.required}
            invalid={!isValid}
            onChange={(e) => onChange(fieldName, e)}
            innerRef={innerRef}
          />
          <FormFeedback>
            <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
          </FormFeedback>
        </FormGroup>
      );
    }
    case CustomAttributeType.Date: {
      return (
        <FormGroup>
          <Label for={inputId}>{definition.label}</Label>
          <RequiredLabelIndicator required={definition.required} />
          <InventoryDatePicker
            name={fieldName}
            id={inputId}
            selected={firstValue}
            required={definition.required}
            invalid={!isValid}
            onChange={(date) => {
              onDateChange(fieldName, date);
            }}
            innerRef={innerRef}
          />
          <FormFeedback>
            <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
          </FormFeedback>
        </FormGroup>
      );
    }
    case CustomAttributeType.Dropdown: {
      return (
        <FormGroup>
          <Label for={inputId}>{definition.label}</Label>
          <RequiredLabelIndicator required={definition.required} />
          <Input
            name={fieldName}
            type="select"
            id={inputId}
            defaultValue={firstValue}
            invalid={!isValid}
            onChange={(e) => onChange(fieldName, e)}
            innerRef={innerRef}
          >
            <option />
            {definition.possibleValues.map((v) => (
              <option key={`${v}value`}>{v}</option>
            ))}
          </Input>
          <FormFeedback>
            <FormattedMessage id="generic.is.required" values={{ subject: definition.label }} />
          </FormFeedback>
        </FormGroup>
      );
    }
    case CustomAttributeType.Radio: {
      return (
        <FormGroup>
          <label>{definition.label}</label>
          <RequiredLabelIndicator required={definition.required} />
          <div>
            {definition.possibleValues.map((v, index) => {
              return (
                <FormGroup check inline key={inputId + index}>
                  <Input
                    name={fieldName}
                    inline
                    id={inputId + index}
                    label={v}
                    type="radio"
                    value={v}
                    defaultChecked={isChecked(v)}
                    onChange={(e) => onChange(fieldName, e)}
                    invalid={!isValid}
                    innerRef={innerRef}
                  />
                  <Label check htmlFor={inputId + index}>
                    {v}
                  </Label>
                </FormGroup>
              );
            })}
          </div>
          {feedback}
        </FormGroup>
      );
    }
    case CustomAttributeType.ChemicalSafety: {
      return (
        <FormGroup>
          <label id={`${inputId}label`} htmlFor={fieldName}>
            {definition.label}
          </label>
          <RequiredLabelIndicator required={definition.required} />
          <div>
            {definition.possibleValues
              .sort((v1, v2) => v1.localeCompare(v2))
              .map((v, index) => {
                return (
                  <CheckboxLabel className="me-2" key={inputId + v}>
                    <CheckboxInput
                      name={fieldName}
                      id={inputId + index}
                      uncheckedValue="0"
                      checked={isChecked(v)}
                      value={v}
                      invalid={!isValid}
                      innerRef={innerRef}
                      onChange={(e) => onCheckboxChange(fieldName, e)}
                    />
                    <span>{v}</span>
                  </CheckboxLabel>
                );
              })}
          </div>
          {feedback}
        </FormGroup>
      );
    }
    default: {
      return <></>;
    }
  }
};
