import { CustomAttribute, InventoryType } from "@labarchives/inventory-shared/build/inventory";
import { useState } from "react";
import { CustomAttributeDefinitionView, InventoryTypeView } from "../../inventory/types/views";
import { getAttributeFromView } from "../selectors";

export interface ManageInventoryTypeHooks {
  deletingAttribute?: CustomAttributeDefinitionView;
  isAdding: boolean;

  onAttributeAdded(attribute: CustomAttribute): void;

  onAttributeUpdated(attribute: CustomAttribute): void;

  onAttributeDeleted(attribute: CustomAttributeDefinitionView): void;

  onDeleteAttributePrompt(attribute: CustomAttributeDefinitionView): void;

  onAttributeDeleteCancelled(): void;

  onDeleteNewAttribute(): void;

  onToggleAddAttribute(): void;

  isAttributeValid(attribute: CustomAttribute): boolean;
}

export interface ManageInventoryTypeProps {
  inventoryTypeView?: InventoryTypeView;

  onUpdated(inventoryType: InventoryType): void;

  onAttributeAdded(inventoryTypeId: number, attribute: CustomAttribute): void;

  onCancel(): void;

  isNameValid(name: string, id: number | undefined): boolean;
}

export function useManageInventoryTypeCustomFields(props: ManageInventoryTypeProps): ManageInventoryTypeHooks {
  const [deletingAttribute, setDeletingAttribute] = useState<CustomAttributeDefinitionView | undefined>();
  const [isAdding, setIsAdding] = useState(false);

  function getInventoryType(customAttributes: CustomAttribute[], inventoryTypeView: InventoryTypeView): InventoryType {
    return {
      id: inventoryTypeView.id,
      name: inventoryTypeView.name,
      customAttributes,
      isDefault: inventoryTypeView.isDefault,
      isLocked: inventoryTypeView.isLocked || false,
      color: inventoryTypeView.color || null,
      standardFieldConfiguration: inventoryTypeView.standardFieldConfiguration,
    };
  }

  const onAttributeAdded = (attribute: CustomAttribute): void => {
    if (!props.inventoryTypeView) {
      return;
    }

    props.onAttributeAdded(props.inventoryTypeView.id, attribute);
    setIsAdding(false);
  };

  const onAttributeUpdated = (attribute: CustomAttribute): void => {
    if (!props.inventoryTypeView) {
      return;
    }

    const attributes: CustomAttribute[] = props.inventoryTypeView.attributes
      .filter((attr) => attr.id !== attribute.id)
      .map((attr) => getAttributeFromView(attr));
    attributes.push(attribute);

    const type: InventoryType = getInventoryType(attributes, props.inventoryTypeView);

    props.onUpdated(type);
  };

  const onAttributeDeleted = (attribute: CustomAttributeDefinitionView): void => {
    if (!props.inventoryTypeView) {
      return;
    }

    const attributes: CustomAttribute[] = props.inventoryTypeView.attributes
      .filter((attr) => attr.id !== attribute.id)
      .map((attr) => getAttributeFromView(attr));

    const type: InventoryType = getInventoryType(attributes, props.inventoryTypeView);

    props.onUpdated(type);
    setDeletingAttribute(undefined);
  };

  const onDeleteAttributePrompt = (attribute: CustomAttributeDefinitionView): void => {
    setDeletingAttribute(attribute);
  };

  const onAttributeDeleteCancelled = (): void => {
    setDeletingAttribute(undefined);
  };

  const onDeleteNewAttribute = (): void => {
    setIsAdding(false);
  };

  const onToggleAddAttribute = (): void => {
    setIsAdding(true);
  };

  const isAttributeValid = (attribute: CustomAttribute): boolean => {
    if (attribute.label.trim() === "") {
      return false;
    }

    const standardFieldNames = new Set([
      "name",
      "inventory type",
      "quantity",
      "units",
      "location",
      "description",
      "vendor",
      "catalog #",
      "lot #",
      "price",
      "date received",
      "grant #",
      "po #",
      "expiration",
      "safety sheet",
      "notes",
    ]);

    const isDuplicate = props.inventoryTypeView?.attributes.find(
      (a) => a.id !== attribute.id && a.label.trim().toLocaleLowerCase() === attribute.label.trim().toLocaleLowerCase(),
    );

    const isReservedName = standardFieldNames.has(attribute.label.trim().toLocaleLowerCase());

    return !isDuplicate && !isReservedName;
  };

  return {
    deletingAttribute,
    isAdding,
    onAttributeAdded,
    onAttributeUpdated,
    onAttributeDeleted,
    onDeleteAttributePrompt,
    onAttributeDeleteCancelled,
    onDeleteNewAttribute,
    onToggleAddAttribute,
    isAttributeValid,
  };
}
