import { CustomAttribute, InventoryStandardField, InventoryType } from "@labarchives/inventory-shared/build/inventory";
import { CustomAttributeDefinitionView, InventoryTypeView } from "../inventory/types/views";
import { InventoryTypesState } from "./types/state";

export function getCustomAttributeDefinitionView(inventoryType: InventoryType): CustomAttributeDefinitionView[] {
  // eslint-disable-next-line unicorn/no-array-reduce
  return inventoryType.customAttributes.reduce((accum: CustomAttributeDefinitionView[], item: CustomAttribute): CustomAttributeDefinitionView[] => {
    accum.push({
      id: item.id,
      label: item.label,
      possibleValues: item.possibleValues,
      type: item.type,
      required: item.required,
      inputId: `custom${item.id}`,
      isLocked: item.isLocked,
    });
    return accum.sort((a, b) => a.label.localeCompare(b.label));
  }, []);
}

export function getInventoryTypeViews(inventoryTypesState: InventoryTypesState): InventoryTypeView[] {
  return inventoryTypesState.allIds
    .map((id) => inventoryTypesState.byId[id])
    .map((inventoryType) => {
      return {
        id: inventoryType.id,
        name: inventoryType.name,
        attributes: getCustomAttributeDefinitionView(inventoryType).sort((a1, a2) => a1.label.localeCompare(a2.label)),
        isDefault: inventoryType.isDefault,
        isLocked: inventoryType.isLocked,
        color: inventoryType.color || undefined,
        standardFieldConfiguration: inventoryType.standardFieldConfiguration || [],
      };
    })
    .sort((t1, t2) => t1.name.localeCompare(t2.name));
}

export function getAttributeFromView(attributeView: CustomAttributeDefinitionView): CustomAttribute {
  return {
    id: attributeView.id,
    type: attributeView.type,
    label: attributeView.label,
    possibleValues: attributeView.possibleValues,
    required: attributeView.required,
    isLocked: attributeView.isLocked || false,
  };
}

export function getInventoryTypeFromView(inventoryTypeView: InventoryTypeView): InventoryType {
  return {
    id: inventoryTypeView.id,
    name: inventoryTypeView.name,
    customAttributes: inventoryTypeView.attributes.map((a) => getAttributeFromView(a)),
    isDefault: inventoryTypeView.isDefault,
    isLocked: inventoryTypeView.isLocked || false,
    color: inventoryTypeView.color || null,
    standardFieldConfiguration: inventoryTypeView.standardFieldConfiguration,
  };
}

export function isInventoryStorageLocationRequired(inventoryTypesState: InventoryTypesState, typeId: number): boolean {
  const configuration = inventoryTypesState.byId[typeId]?.standardFieldConfiguration ?? [];
  return configuration.find((c) => c.field === InventoryStandardField.Location)?.required === true;
}
