import DatePicker, {registerLocale} from "react-datepicker";
import { enUS } from "date-fns/locale/en-US";
import { enGB } from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import React, { ReactElement } from "react";
import moment from "moment";
import * as clock from "@labarchives/inventory-shared/build/util/clock";
import "./DatePicker.scss";
import classNames from "classnames";
import { useDatePicker } from "./DatePickerHooks";

export interface DatePickerProps {
  id: string;
  name: string;
  selected?: Date | string | null;
  className?: string;
  required: boolean;
  invalid?: boolean;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;

  onChange(date: Date | null, dateAsString: string | null): void;
}

export function InventoryDatePicker(props: DatePickerProps): ReactElement {
  const { onChange } = useDatePicker({ onChange: props.onChange });

  const getSelected = (): Date | null => {
    if (!props.selected) {
      return null;
    }

    const type = typeof props.selected;
    if (props.selected instanceof Date) {
      return props.selected;
    }
    if (type === "string") {
      return moment(props.selected, clock.STANDARD_MOMENT_DATE_FORMAT).toDate();
    }

    return null;
  };

  const locale = navigator.language === "en-US" ? "en-US" : "en-GB";
  if (locale !== "en-US") {
    registerLocale("en-GB", enGB);
  }
  // of course ReactDatePicker uses a different format than everything else in the world. this adapts to their craziness
  // and for some reason things like en-AU don't format correctly, so we just use GB if it's anything other than US
  const dateFnsFormat = moment.localeData(locale).longDateFormat("L").replace("DD", "dd").replace("YYYY", "yyyy");

  return (
    <>
      {props.innerRef && <input type="hidden" ref={props.innerRef} />}
      <DatePicker
        id={props.id}
        selected={getSelected()}
        name={props.name}
        required={props.required}
        onChange={onChange}
        className={classNames(props.className, {
          "is-invalid": props.invalid,
          "form-control": true,
          "inventory-date-picker": true,
        })}
        dateFormat={dateFnsFormat}
        placeholderText={dateFnsFormat.toLowerCase()}
        disabled={props.disabled}
        autoComplete="off"
        isClearable={!props.required}
        locale={locale}
        popperPlacement={"bottom-start"}
        showMonthDropdown={true}
        showYearDropdown={true}
        maxDate={new Date(4000, 0)}
        minDate={new Date(1900, 0)}
      />
    </>
  );
}
