import { FormGroup, Label } from "reactstrap";
import React, { ReactElement } from "react";
import { AdvancedSearchCriteriaMatchType, CustomAttributeType } from "@labarchives/inventory-shared/build/inventory";
import { InventoryDatePicker } from "../../../components/DatePicker/InventoryDatePicker";
import { InventoryAdvancedSearchTypeRangeComponentProps } from "./InventoryAdvancedSearchTypeComponentProps";
import { InventoryAdvanceSearchMatchDropdown } from "./InventoryAdvanceSearchMatchDropdown";

export function InventoryAdvancedSearchDate(props: InventoryAdvancedSearchTypeRangeComponentProps): ReactElement {
  return (
    <>
      <InventoryAdvanceSearchMatchDropdown
        index={props.index}
        matchType={props.matchType}
        onMatchTypeChanged={props.onMatchTypeChanged}
        attributeType={CustomAttributeType.Date}
      />
      <div className={"criteria-value"}>
        <FormGroup className={"range-option"}>
          <Label className="visually-hidden" for={`date1${props.index}`}>
            Date Value
          </Label>
          <InventoryDatePicker
            className="range-option form-control-sm"
            id={`date1${props.index}`}
            name={`date1${props.index}`}
            required={false}
            selected={props.defaultValue}
            onChange={(date, dateString) => props.onValueChanged(dateString || "")}
          />
        </FormGroup>
        {props.matchType === AdvancedSearchCriteriaMatchType.Between && (
          <>
            <span className="range-option"> - </span>
            <FormGroup className={"range-option"}>
              <Label className="visually-hidden" for={`date2${props.index}`}>
                Date Value
              </Label>
              <InventoryDatePicker
                className="range-option form-control-sm"
                id={`date2${props.index}`}
                name={`date2${props.index}`}
                required={false}
                selected={props.defaultEndValue}
                onChange={(date, dateString) => props.onValueChanged(dateString || "", true)}
              />
            </FormGroup>
          </>
        )}
      </div>
    </>
  );
}
